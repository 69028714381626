import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ResponsiveContainer, CartesianGrid, ComposedChart, Line, Tooltip, XAxis, YAxis } from 'recharts'
import { COLORS } from '../../../../../constants'
import Row from '../../../../../../inplant-components-fe/ui/components/Grid/Row'
import { consoleLog } from '../../../../../../inplant-components-fe/mvfunctions/logs'
import EngineTorqueDensifier from './_EngineSpeedDensifier'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'
import { DEFAULT_STATE } from './_constants'
import { HHHmmssFromMilliseconds } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/shared'
import moment from 'moment'
import { reduceWarnAreas } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/sharedV2'
import { EngineSpeedDensifierProps, EngineSpeedDensifierState } from './_types'

interface EngineTorqueDensifierViewState extends EngineSpeedDensifierState {
  tooltipData: any
  showTooltipLine1: boolean
  showTooltipLine2: boolean
  showTooltipLine3: boolean
  showTooltipLine4: boolean
  showTooltipLine5: boolean
}

function propsToOwnState(props: EngineSpeedDensifierProps): EngineTorqueDensifierViewState {
  return {
    ...DEFAULT_STATE,
    showTooltipLine1: true,
    showTooltipLine2: true,
    showTooltipLine3: true,
    showTooltipLine4: true,
    showTooltipLine5: true,
    tooltipData: null,
  }
}

class EngineSpeedDensifierView extends EngineTorqueDensifier<
  EngineSpeedDensifierProps,
  EngineTorqueDensifierViewState
> {
  constructor(props: EngineSpeedDensifierProps) {
    super(props, propsToOwnState)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }
  private handleMouseEnter(
    graph: 'showTooltipLine1' | 'showTooltipLine2' | 'showTooltipLine3' | 'showTooltipLine4' | 'showTooltipLine5'
  ) {
    switch (graph) {
      case 'showTooltipLine1':
        this.setState({
          showTooltipLine1: true,
        })
        return
      case 'showTooltipLine2':
        this.setState({
          showTooltipLine2: true,
        })
        return
      case 'showTooltipLine3':
        this.setState({
          showTooltipLine3: true,
        })
        return
      case 'showTooltipLine4':
        this.setState({
          showTooltipLine4: true,
        })
        return
      case 'showTooltipLine5':
        this.setState({
          showTooltipLine5: true,
        })
        return
    }
  }

  private handleMouseLeave(
    graph: 'showTooltipLine1' | 'showTooltipLine2' | 'showTooltipLine3' | 'showTooltipLine4' | 'showTooltipLine5'
  ) {
    switch (graph) {
      case 'showTooltipLine1':
        this.setState({
          showTooltipLine1: false,
          tooltipData: null,
        })
        return
      case 'showTooltipLine2':
        this.setState({
          showTooltipLine2: false,
          tooltipData: null,
        })
        return
      case 'showTooltipLine3':
        this.setState({
          showTooltipLine3: false,
          tooltipData: null,
        })
        return
      case 'showTooltipLine4':
        this.setState({
          showTooltipLine4: false,
          tooltipData: null,
        })
        return
      case 'showTooltipLine5':
        this.setState({
          showTooltipLine5: false,
          tooltipData: null,
        })
        return
    }
  }

  private handleMouseMove(
    args: any,
    graph: 'showTooltipLine1' | 'showTooltipLine2' | 'showTooltipLine3' | 'showTooltipLine4' | 'showTooltipLine5'
  ) {
    if (args && args.activeLabel) {
      try {
        const curData = this.state.filteredData && this.state.filteredData.find((e: any) => e.time === args.activeLabel)
        const curLine1Threshold = curData.line1Threshold !== undefined ? curData.line1Threshold : '--'
        const curLine1Values = curData.line1Values !== undefined ? curData.line1Values : '--'
        const curLine1SpeedValues = curData.line1SpeedValues !== undefined ? curData.line1SpeedValues : '--'
        const curLine2Threshold = curData.line2Threshold !== undefined ? curData.line2Threshold : '--'
        const curLine2Values = curData.line2Values !== undefined ? curData.line2Values : '--'
        const curLine2SpeedValues = curData.line2SpeedValues !== undefined ? curData.line2SpeedValues : '--'
        const curLine3Threshold = curData.line3Threshold !== undefined ? curData.line3Threshold : '--'
        const curLine3Values = curData.line3Values !== undefined ? curData.line3Values : '--'
        const curLine3SpeedValues = curData.line3SpeedValues !== undefined ? curData.line3SpeedValues : '--'
        const curLine4Threshold = curData.line4Threshold !== undefined ? curData.line4Threshold : '--'
        const curLine4Values = curData.line4Values !== undefined ? curData.line4Values : '--'
        const curLine4SpeedValues = curData.line4SpeedValues !== undefined ? curData.line4SpeedValues : '--'
        const curLine5Threshold = curData.line5Threshold !== undefined ? curData.line5Threshold : '--'
        const curLine5Values = curData.line5Values !== undefined ? curData.line5Values : '--'
        const curLine5SpeedValues = curData.line5SpeedValues !== undefined ? curData.line5SpeedValues : '--'

        this.setState({
          showTooltipLine1: graph === 'showTooltipLine1',
          showTooltipLine2: graph === 'showTooltipLine2',
          showTooltipLine3: graph === 'showTooltipLine3',
          showTooltipLine4: graph === 'showTooltipLine4',
          showTooltipLine5: graph === 'showTooltipLine5',
          tooltipData: {
            line1Threshold: curLine1Threshold,
            line1Values: curLine1Values,
            line1SpeedValues: curLine1SpeedValues,
            line2Threshold: curLine2Threshold,
            line2Values: curLine2Values,
            line2SpeedValues: curLine2SpeedValues,
            line3Threshold: curLine3Threshold,
            line3Values: curLine3Values,
            line3SpeedValues: curLine3SpeedValues,
            line4Threshold: curLine4Threshold,
            line4Values: curLine4Values,
            line4SpeedValues: curLine4SpeedValues,
            line5Threshold: curLine5Threshold,
            line5Values: curLine5Values,
            line5SpeedValues: curLine5SpeedValues,
            line1ThresholdColor: COLORS.lines.torque.thr,
            line1ValuesColor: COLORS.lines.torque.line1,
            line1SpeedValuesColor: COLORS.lines.torque.line1Speed,
            line2ThresholdColor: COLORS.lines.torque.thr,
            line2ValuesColor: COLORS.lines.torque.line2,
            line2SpeedValuesColor: COLORS.lines.torque.line2Speed,
            line3ThresholdColor: COLORS.lines.torque.thr,
            line3ValuesColor: COLORS.lines.torque.line3,
            line3SpeedValuesColor: COLORS.lines.torque.line3Speed,
            line4ThresholdColor: COLORS.lines.torque.thr,
            line4ValuesColor: COLORS.lines.torque.line4,
            line4SpeedValuesColor: COLORS.lines.torque.line3Speed,
            line5ThresholdColor: COLORS.lines.torque.thr,
            line5ValuesColor: COLORS.lines.torque.line5,
            line5SpeedValuesColor: COLORS.lines.torque.line3Speed,
            time: curData.time,
          },
        })
      } catch (error) {
        consoleLog('error', error)
      }
    }
  }

  private static nullTooltipContent() {
    return <div className="null-tooltip-content" />
  }

  public renderContent(): JSX.Element {
    const { t } = this.props
    const highlightedAxisKey = 'highlighted'

    const line1TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line1, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })
    const line2TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line2, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })
    const line3TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line3, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })
    const line4TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line4, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })
    const line5TorqueFrom25To78PercentArea = reduceWarnAreas(this.state.warnAreas.fromZeroThrsToPlcThrs.line5, {
      yAxisId: highlightedAxisKey,
      color: COLORS.highlighted,
      opacity: 0.5,
    })

    const line1ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line1, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })
    const line2ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line2, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })
    const line3ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line3, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })
    const line4ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line4, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })
    const line5ZeroTorqueArea = reduceWarnAreas(this.state.warnAreas.zeroThrs.line5, {
      yAxisId: highlightedAxisKey,
      color: COLORS.danger,
      opacity: 0.5,
    })

    return (
      <>
        <h3 className={'w-100'}>{this.props.t(this.chartTranslationKeyTitle)}</h3>
        <div className="d-flex d-flex-row  d-print-block page-break-inside-avoid">
          <div className={`col-md-12 col-sm-12 col-lg-12`}>
            <Row>
              <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                <h5 className={'w-100'}>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine1')}</h5>
                <ResponsiveContainer width="100%" height={400}>
                  <ComposedChart
                    data={this.state.filteredData}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    onMouseEnter={() => this.handleMouseEnter('showTooltipLine1')}
                    onMouseMove={data => this.handleMouseMove(data, 'showTooltipLine1')}
                    onMouseLeave={() => this.handleMouseLeave('showTooltipLine1')}
                    barGap={0}
                    barCategoryGap={0}
                  >
                    <XAxis
                      key={'line1'}
                      hide={false}
                      dataKey="time"
                      label={''}
                      height={30}
                      axisLine={true}
                      tickLine={false}
                      tick={true}
                      interval={'preserveStartEnd'}
                      minTickGap={50}
                      domain={['dataMin', 'dataMax']}
                      tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                      type={'category'}
                    />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Tooltip content={EngineSpeedDensifierView.nullTooltipContent} />
                    <YAxis
                      domain={[0, 100]}
                      orientation={'left'}
                      yAxisId={'meters'}
                      axisLine={false}
                      tickFormatter={val => parseInt(val, 10)}
                    />
                    <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
                    {line1TorqueFrom25To78PercentArea}
                    {line1ZeroTorqueArea}
                    <YAxis
                      domain={[0, 100]}
                      orientation={'right'}
                      yAxisId={'rotation'}
                      axisLine={false}
                      tickFormatter={val => parseInt(val, 10)}
                    />
                    <Line
                      dataKey="line1Threshold"
                      stroke={COLORS.lines.torque.thr}
                      dot={false}
                      strokeWidth={2}
                      type="step"
                      isAnimationActive={false}
                      yAxisId={'meters'}
                    />
                    <Line
                      dataKey="line1Values"
                      stroke={COLORS.lines.torque.line1}
                      dot={false}
                      strokeWidth={2}
                      type="step"
                      isAnimationActive={false}
                      yAxisId={'meters'}
                    />
                    <Line
                      dataKey="line1SpeedValues"
                      stroke={COLORS.lines.torque.line1Speed}
                      dot={false}
                      strokeWidth={2}
                      type="step"
                      isAnimationActive={false}
                      yAxisId={'rotation'}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
                <Row>
                  <div className="d-none d-print-inline-flex">
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className="label-color-square"
                          style={{
                            backgroundColor: COLORS.lines.torque.line1,
                          }}
                        />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1Values')}</span>
                      </span>
                    </Row>
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className="label-color-square"
                          style={{
                            backgroundColor: COLORS.lines.torque.thr,
                          }}
                        />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1Threshold')}</span>
                      </span>
                    </Row>
                  </div>
                </Row>
              </div>
              <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                {this.state.showTooltipLine1 && this.state.tooltipData !== null && (
                  <ul className="side-tooltip">
                    <li>
                      <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                      <br />
                      <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                    </li>

                    {this.state.tooltipData.line1Threshold !== null && (
                      <li>
                        <strong>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1Threshold')}</strong>
                        <br />
                        <span
                          className="label-color label-color-line"
                          style={{ backgroundColor: `${this.state.tooltipData.line1ThresholdColor}` }}
                        />
                        <span>{parseFloat(this.state.tooltipData.line1Threshold).toFixed(2)} %</span>
                      </li>
                    )}

                    {this.state.tooltipData.line1Values !== null && (
                      <li>
                        <strong>{t('plantAnalysis.lines.engineSpeedDensifier.label.line1Values')}</strong>
                        <br />
                        <span
                          className="label-color label-color-line"
                          style={{ backgroundColor: `${this.state.tooltipData.line1ValuesColor}` }}
                        />
                        <span>{parseFloat(this.state.tooltipData.line1Values).toFixed(2)} %</span>
                      </li>
                    )}
                    {this.state.tooltipData.line1SpeedValues !== null && (
                      <li>
                        <strong>
                          {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line1SpeedValues')}
                        </strong>
                        <br />
                        <span
                          className="label-color label-color-line"
                          style={{ backgroundColor: `${this.state.tooltipData.line1SpeedValuesColor}` }}
                        />
                        <span>{parseFloat(this.state.tooltipData.line1SpeedValues).toFixed(2)} rpm</span>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </Row>
          </div>
        </div>
        <div className={'col-lg-12 col-md-12 col-sm-12'}>
          <div>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine1') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line1')].find((v: { key: string; value: number }) =>
                    v.key.includes('percent')
                  ).value
                ).toFixed(2)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine1') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line1')].find((v: { key: string; value: number }) =>
                    v.key.includes('count')
                  )?.value
                ).toFixed(0)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine1') + ': '}</b>
              {this.state.data &&
                HHHmmssFromMilliseconds(
                  this.state.data?.[this.getKeyFromLine('line1')].find((v: { key: string; value: number }) =>
                    v.key.includes('seconds')
                  )?.value * 1000,
                  false,
                  true,
                  false
                )}
            </span>
            <span className="mr-5">
              <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
              {this.state.highlightInfoData.line1?.counts['10'] ?? 0}
            </span>
          </div>
        </div>
        <div className="d-flex d-flex-row  d-print-block page-break-inside-avoid">
          <div className={`col-md-12 col-sm-12 col-lg-12`}>
            <div className={'graph-spacing column-break-before'}>
              <Row>
                <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                  <h5 className={'w-100'}>
                    {this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine2')}
                  </h5>
                  <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                      width={700}
                      height={400}
                      data={this.state.filteredData}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      onMouseEnter={() => this.handleMouseEnter('showTooltipLine2')}
                      onMouseMove={data => this.handleMouseMove(data, 'showTooltipLine2')}
                      onMouseLeave={() => this.handleMouseLeave('showTooltipLine2')}
                      barGap={0}
                      barCategoryGap={0}
                    >
                      <XAxis
                        key={'line2'}
                        hide={false}
                        dataKey="time"
                        label={''}
                        height={30}
                        axisLine={true}
                        tickLine={false}
                        tick={true}
                        interval={'preserveStartEnd'}
                        minTickGap={50}
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                        type={'category'}
                      />
                      <CartesianGrid stroke="#f5f5f5" />
                      <Tooltip content={EngineSpeedDensifierView.nullTooltipContent} />
                      <YAxis
                        domain={[0, 100]}
                        orientation={'left'}
                        yAxisId={'meters'}
                        axisLine={false}
                        tickFormatter={val => parseInt(val, 10)}
                      />
                      <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
                      {line2TorqueFrom25To78PercentArea}
                      {line2ZeroTorqueArea}
                      <YAxis
                        domain={[0, 100]}
                        orientation={'right'}
                        yAxisId={'rotation'}
                        axisLine={false}
                        tickFormatter={val => parseInt(val, 10)}
                      />
                      <Line
                        dataKey="line2Threshold"
                        stroke={COLORS.lines.torque.thr}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'meters'}
                      />
                      <Line
                        dataKey="line2Values"
                        stroke={COLORS.lines.torque.line2}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'meters'}
                      />
                      <Line
                        dataKey="line2SpeedValues"
                        stroke={COLORS.lines.torque.line2Speed}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'rotation'}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                  <div className="d-none d-print-inline-flex">
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span className="label-color-square" style={{ backgroundColor: COLORS.lines.torque.line2 }} />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line2Values')}</span>
                      </span>
                    </Row>
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span className="label-color-square" style={{ backgroundColor: COLORS.lines.torque.thr }} />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line2Threshold')}</span>
                      </span>
                    </Row>
                  </div>
                </div>
                <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                  {this.state.showTooltipLine2 && this.state.tooltipData !== null && (
                    <ul className="side-tooltip">
                      <li>
                        <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                        <br />
                        <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                      </li>

                      {this.state.tooltipData.line2Threshold !== null && (
                        <li>
                          <strong>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line2Threshold')}
                          </strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line2ThresholdColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line2Threshold).toFixed(2)} %</span>
                        </li>
                      )}

                      {this.state.tooltipData.line2Values !== null && (
                        <li>
                          <strong>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line2Values')}</strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line2ValuesColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line2Values).toFixed(2)} %</span>
                        </li>
                      )}
                      {this.state.tooltipData.line2SpeedValues !== null && (
                        <li>
                          <strong>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line2SpeedValues')}
                          </strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line2SpeedValuesColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line2SpeedValues).toFixed(2)} rpm</span>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </Row>
            </div>
          </div>
        </div>
        <div className={'col-lg-12 col-md-12 col-sm-12'}>
          <div>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine2') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line2')].find((v: { key: string; value: number }) =>
                    v.key.includes('percent')
                  ).value
                ).toFixed(2)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine2') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line2')].find((v: { key: string; value: number }) =>
                    v.key.includes('count')
                  )?.value
                ).toFixed(0)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine2') + ': '}</b>
              {this.state.data &&
                HHHmmssFromMilliseconds(
                  this.state.data?.[this.getKeyFromLine('line2')].find((v: { key: string; value: number }) =>
                    v.key.includes('seconds')
                  )?.value * 1000,
                  false,
                  true,
                  false
                )}
            </span>
            <span className="mr-5">
              <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
              {this.state.highlightInfoData.line2?.counts['10'] ?? 0}
            </span>
          </div>
        </div>
        <div className="d-flex d-flex-row  d-print-block page-break-inside-avoid">
          <div className={`col-md-12 col-sm-12 col-lg-12`}>
            <div className={'graph-spacing column-break-before'}>
              <Row>
                <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                  <h5 className={'w-100'}>
                    {this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine5')}
                  </h5>
                  <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                      width={700}
                      height={400}
                      data={this.state.filteredData}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      onMouseEnter={() => this.handleMouseEnter('showTooltipLine5')}
                      onMouseMove={data => this.handleMouseMove(data, 'showTooltipLine5')}
                      onMouseLeave={() => this.handleMouseLeave('showTooltipLine5')}
                      barGap={0}
                      barCategoryGap={0}
                    >
                      <XAxis
                        key={'line5'}
                        hide={false}
                        dataKey="time"
                        label={''}
                        height={30}
                        axisLine={true}
                        tickLine={false}
                        tick={true}
                        interval={'preserveStartEnd'}
                        minTickGap={50}
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                        type={'category'}
                      />
                      <CartesianGrid stroke="#f5f5f5" />
                      {line5TorqueFrom25To78PercentArea}
                      {line5ZeroTorqueArea}
                      <Tooltip content={EngineSpeedDensifierView.nullTooltipContent} />
                      <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
                      <YAxis
                        domain={[0, 100]}
                        orientation={'left'}
                        yAxisId={'meters'}
                        axisLine={false}
                        tickFormatter={val => parseInt(val, 10)}
                      />
                      <YAxis
                        domain={[0, 100]}
                        orientation={'right'}
                        yAxisId={'rotation'}
                        axisLine={false}
                        tickFormatter={val => parseInt(val, 10)}
                      />
                      <YAxis domain={[0, 100]} axisLine={false} yAxisId={'highlighted'} />
                      <Line
                        dataKey="line5Threshold"
                        stroke={COLORS.lines.torque.thr}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'meters'}
                      />
                      <Line
                        dataKey="line5Values"
                        stroke={COLORS.lines.torque.line5}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'meters'}
                      />
                      <Line
                        dataKey="line5SpeedValues"
                        stroke={COLORS.lines.torque.line5Speed}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'rotation'}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                  <div className="d-none d-print-inline-flex">
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span className="label-color-square" style={{ backgroundColor: COLORS.lines.torque.line5 }} />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line5Values')}</span>
                      </span>
                    </Row>
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span className="label-color-square" style={{ backgroundColor: COLORS.lines.torque.thr }} />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line5Threshold')}</span>
                      </span>
                    </Row>
                  </div>
                </div>
                <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                  {this.state.showTooltipLine5 && this.state.tooltipData !== null && (
                    <ul className="side-tooltip">
                      <li>
                        <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                        <br />
                        <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                      </li>

                      {this.state.tooltipData.line5Threshold !== null && (
                        <li>
                          <strong>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line5Threshold')}
                          </strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line5ThresholdColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line5Threshold).toFixed(2)} %</span>
                        </li>
                      )}

                      {this.state.tooltipData.line5Values !== null && (
                        <li>
                          <strong>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line5Values')}</strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line5ValuesColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line5Values).toFixed(2)} %</span>
                        </li>
                      )}

                      {this.state.tooltipData.line5SpeedValues !== null && (
                        <li>
                          <strong>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line5SpeedValues')}
                          </strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line5SpeedValuesColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line5SpeedValues).toFixed(2)} rpm</span>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </Row>
            </div>
          </div>
        </div>
        <div className={'col-lg-12 col-md-12 col-sm-12'}>
          <div>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine5') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line5')].find((v: { key: string; value: number }) =>
                    v.key.includes('percent')
                  ).value
                ).toFixed(2)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine5') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line5')].find((v: { key: string; value: number }) =>
                    v.key.includes('count')
                  )?.value
                ).toFixed(0)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine5') + ': '}</b>
              {this.state.data &&
                HHHmmssFromMilliseconds(
                  this.state.data?.[this.getKeyFromLine('line5')].find((v: { key: string; value: number }) =>
                    v.key.includes('seconds')
                  )?.value * 1000,
                  false,
                  true,
                  false
                )}
            </span>
            <span className="mr-5">
              <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
              {this.state.highlightInfoData.line5?.counts['10'] ?? 0}
            </span>
          </div>
        </div>
        <div className="d-flex d-flex-row  d-print-block page-break-inside-avoid">
          <div className={`col-md-12 col-sm-12 col-lg-12`}>
            <div className={'graph-spacing column-break-before'}>
              <Row>
                <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                  <h5 className={'w-100'}>
                    {this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine3')}
                  </h5>
                  <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                      width={700}
                      height={400}
                      data={this.state.filteredData}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      onMouseEnter={() => this.handleMouseEnter('showTooltipLine3')}
                      onMouseMove={data => this.handleMouseMove(data, 'showTooltipLine3')}
                      onMouseLeave={() => this.handleMouseLeave('showTooltipLine3')}
                      barGap={0}
                      barCategoryGap={0}
                    >
                      <XAxis
                        key={'line3'}
                        hide={false}
                        dataKey="time"
                        label={''}
                        height={30}
                        axisLine={true}
                        tickLine={false}
                        tick={true}
                        interval={'preserveStartEnd'}
                        minTickGap={50}
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                        type={'category'}
                      />
                      <CartesianGrid stroke="#f5f5f5" />
                      {line3TorqueFrom25To78PercentArea}
                      {line3ZeroTorqueArea}
                      <Tooltip content={EngineSpeedDensifierView.nullTooltipContent} />
                      <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
                      <YAxis
                        domain={[0, 100]}
                        orientation={'left'}
                        yAxisId={'meters'}
                        axisLine={false}
                        tickFormatter={val => parseInt(val, 10)}
                      />
                      <YAxis
                        domain={[0, 100]}
                        orientation={'right'}
                        yAxisId={'rotation'}
                        axisLine={false}
                        tickFormatter={val => parseInt(val, 10)}
                      />
                      <YAxis domain={[0, 100]} axisLine={false} yAxisId={'highlighted'} />
                      <Line
                        dataKey="line3Threshold"
                        stroke={COLORS.lines.torque.thr}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'meters'}
                      />
                      <Line
                        dataKey="line3Values"
                        stroke={COLORS.lines.torque.line3}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'meters'}
                      />
                      <Line
                        dataKey="line3SpeedValues"
                        stroke={COLORS.lines.torque.line3Speed}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'rotation'}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                  <div className="d-none d-print-inline-flex">
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span className="label-color-square" style={{ backgroundColor: COLORS.lines.torque.line3 }} />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line3Values')}</span>
                      </span>
                    </Row>
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span className="label-color-square" style={{ backgroundColor: COLORS.lines.torque.thr }} />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line3Threshold')}</span>
                      </span>
                    </Row>
                  </div>
                </div>
                <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                  {this.state.showTooltipLine3 && this.state.tooltipData !== null && (
                    <ul className="side-tooltip">
                      <li>
                        <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                        <br />
                        <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                      </li>

                      {this.state.tooltipData.line3Threshold !== null && (
                        <li>
                          <strong>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line3Threshold')}
                          </strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line3ThresholdColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line3Threshold).toFixed(2)} %</span>
                        </li>
                      )}

                      {this.state.tooltipData.line3Values !== null && (
                        <li>
                          <strong>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line3Values')}</strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line3ValuesColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line3Values).toFixed(2)} %</span>
                        </li>
                      )}

                      {this.state.tooltipData.line3SpeedValues !== null && (
                        <li>
                          <strong>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line3SpeedValues')}
                          </strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line3SpeedValuesColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line3SpeedValues).toFixed(2)} rpm</span>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </Row>
            </div>
          </div>
        </div>
        <div className={'col-lg-12 col-md-12 col-sm-12'}>
          <div>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine3') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line3')].find((v: { key: string; value: number }) =>
                    v.key.includes('percent')
                  ).value
                ).toFixed(2)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine3') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line3')].find((v: { key: string; value: number }) =>
                    v.key.includes('count')
                  )?.value
                ).toFixed(0)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine3') + ': '}</b>
              {this.state.data &&
                HHHmmssFromMilliseconds(
                  this.state.data?.[this.getKeyFromLine('line3')].find((v: { key: string; value: number }) =>
                    v.key.includes('seconds')
                  )?.value * 1000,
                  false,
                  true,
                  false
                )}
            </span>
            <span className="mr-5">
              <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
              {this.state.highlightInfoData.line3?.counts['10'] ?? 0}
            </span>
          </div>
        </div>
        <div className="d-flex d-flex-row  d-print-block page-break-inside-avoid">
          <div className={`col-md-12 col-sm-12 col-lg-12`}>
            <div className={'graph-spacing column-break-before'}>
              <Row>
                <div className="mv-col col-md-11 col-sm-11 col-lg-11 print-full-width">
                  <h5 className={'w-100'}>
                    {this.props.t('plantAnalysis.lines.engineSpeedDensifier.engines.engine4')}
                  </h5>
                  <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                      width={700}
                      height={400}
                      data={this.state.filteredData}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      onMouseEnter={() => this.handleMouseEnter('showTooltipLine4')}
                      onMouseMove={data => this.handleMouseMove(data, 'showTooltipLine4')}
                      onMouseLeave={() => this.handleMouseLeave('showTooltipLine4')}
                      barGap={0}
                      barCategoryGap={0}
                    >
                      <XAxis
                        key={'line4'}
                        hide={false}
                        dataKey="time"
                        label={''}
                        height={30}
                        axisLine={true}
                        tickLine={false}
                        tick={true}
                        interval={'preserveStartEnd'}
                        minTickGap={50}
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={timeStr => moment(timeStr, 'X').format('HH:mm')}
                        type={'category'}
                      />
                      <CartesianGrid stroke="#f5f5f5" />
                      {line4TorqueFrom25To78PercentArea}
                      {line4ZeroTorqueArea}
                      <Tooltip content={EngineSpeedDensifierView.nullTooltipContent} />
                      <YAxis domain={[0, 100]} axisLine={false} hide={true} yAxisId={'highlighted'} />
                      <YAxis
                        domain={[0, 100]}
                        orientation={'left'}
                        yAxisId={'meters'}
                        axisLine={false}
                        tickFormatter={val => parseInt(val, 10)}
                      />
                      <YAxis
                        domain={[0, 100]}
                        orientation={'right'}
                        yAxisId={'rotation'}
                        axisLine={false}
                        tickFormatter={val => parseInt(val, 10)}
                      />
                      <YAxis domain={[0, 100]} axisLine={false} yAxisId={'highlighted'} />
                      <Line
                        dataKey="line4Threshold"
                        stroke={COLORS.lines.torque.thr}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'meters'}
                      />
                      <Line
                        dataKey="line4Values"
                        stroke={COLORS.lines.torque.line4}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'meters'}
                      />
                      <Line
                        dataKey="line4SpeedValues"
                        stroke={COLORS.lines.torque.line4Speed}
                        dot={false}
                        strokeWidth={2}
                        type="step"
                        isAnimationActive={false}
                        yAxisId={'rotation'}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                  <div className="d-none d-print-inline-flex">
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span className="label-color-square" style={{ backgroundColor: COLORS.lines.torque.line4 }} />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line4Values')}</span>
                      </span>
                    </Row>
                    <Row verticalAlignment="center">
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <span className="label-color-square" style={{ backgroundColor: COLORS.lines.torque.thr }} />
                        <span>{t('plantAnalysis.lines.engineSpeedDensifier.label.line4Threshold')}</span>
                      </span>
                    </Row>
                  </div>
                </div>
                <div className="mv-col col-md-1 col-sm-1 col-lg-1 d-print-none">
                  {this.state.showTooltipLine4 && this.state.tooltipData !== null && (
                    <ul className="side-tooltip">
                      <li>
                        <strong>{this.props.t('plantAnalysis.labels.time')}</strong>
                        <br />
                        <span>{moment.unix(this.state.tooltipData.time).format('HH:mm')}</span>
                      </li>

                      {this.state.tooltipData.line4Threshold !== null && (
                        <li>
                          <strong>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line4Threshold')}
                          </strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line4ThresholdColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line4Threshold).toFixed(2)} %</span>
                        </li>
                      )}

                      {this.state.tooltipData.line4Values !== null && (
                        <li>
                          <strong>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line4Values')}</strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line4ValuesColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line4Values).toFixed(2)} %</span>
                        </li>
                      )}

                      {this.state.tooltipData.line4SpeedValues !== null && (
                        <li>
                          <strong>
                            {this.props.t('plantAnalysis.lines.engineSpeedDensifier.label.line4SpeedValues')}
                          </strong>
                          <br />
                          <span
                            className="label-color label-color-line"
                            style={{ backgroundColor: `${this.state.tooltipData.line4SpeedValuesColor}` }}
                          />
                          <span>{parseFloat(this.state.tooltipData.line4SpeedValues).toFixed(2)} rpm</span>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </Row>
            </div>
          </div>
        </div>
        <div className={'col-lg-12 col-md-12 col-sm-12'}>
          <div>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThr.engine4') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line4')].find((v: { key: string; value: number }) =>
                    v.key.includes('percent')
                  ).value
                ).toFixed(2)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.overThrNumber.engine4') + ': '}</b>
              {this.state.data &&
                parseFloat(
                  this.state.data?.[this.getKeyFromLine('line4')].find((v: { key: string; value: number }) =>
                    v.key.includes('count')
                  )?.value
                ).toFixed(0)}
            </span>
            <span className="mr-5">
              <b>{this.props.t('plantAnalysis.lines.engineSpeedDensifier.secondsAtZero.engine4') + ': '}</b>
              {this.state.data &&
                HHHmmssFromMilliseconds(
                  this.state.data?.[this.getKeyFromLine('line4')].find((v: { key: string; value: number }) =>
                    v.key.includes('seconds')
                  )?.value * 1000,
                  false,
                  true,
                  false
                )}
            </span>
            <span className="mr-5">
              <b>{this.props.t(`plantAnalysis.lines.engineSpeedDensifier.countHighlight.10`) + ': '}</b>
              {this.state.highlightInfoData.line4?.counts['10'] ?? 0}
            </span>
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(EngineSpeedDensifierView))
