import * as React from 'react'
import { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { workshiftFetchData, workshiftValidityFetchData } from '../../../../redux/actions/workshift'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { isMoment } from 'moment'
import 'moment/locale/it' // @todo get from language selector
import { updateDateFilter, updateWorkshiftFilter } from '../../../../redux/actions/common'
import { Workshift } from '../../../../types/workshift'
import { GeneralData } from '../../../../types/measure'
import DateRangePickerComponent from '../DateRangePicker/DateRangePickerComponent'
import { getMoment } from '../../../../functions/shared'

// @todo get from language selector

interface OwnState {
  notifications: string[]
  workshifts: number[]
}

interface OwnProps {
  isRenderVisible: boolean
  hideWorkshiftSelector?: boolean
  isRangeDatePicker?: boolean
}

interface StateProps {
  dateFilterStart: moment.Moment
  dateFilterEnd: moment.Moment
  datePickerMaxRange: null | number
  model: null | GeneralData
  plant: any | null
  workshift: null | Workshift
  workshifts: null | GeneralData
  isWithGateway: boolean
}

interface DispatchProps {
  updateDateFilter: (dateStart: moment.Moment, dateEnd: moment.Moment, isManual?: boolean) => void
  updateWorkshiftFilter: (workshift: number, workshifts: GeneralData | null) => void
  workshiftFetchData: (dateStart: moment.Moment, dateEnd: moment.Moment, withGateway: boolean) => Promise<any>
  workshiftValidityFetchData: (
    withGateway: boolean,
    dateFilter: moment.Moment,
    fetchDayAfter?: boolean,
    cutoff?: string
  ) => Promise<any>
}

const mapStateToProps = (state: any): StateProps => ({
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  isWithGateway: (state.config && state.config.plantAnalysis && state.config.plantAnalysis.isWithGateway) ?? false,
  datePickerMaxRange:
    (state.config &&
      state.config.plantAnalysis &&
      state.config.plantAnalysis.datePicker &&
      state.config.plantAnalysis.datePicker.maxRange) ||
    30,
  model: state.plantAnalysis.model,
  plant: state.plantSelector || null,
  workshift: state.plantAnalysis.common.workshift,
  workshifts: state.plantAnalysis.workshifts.workshifts,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    updateDateFilter: (dateStart, dateEnd, isManual) => dispatch(updateDateFilter(dateStart, dateEnd, isManual)),
    updateWorkshiftFilter: (workshift: number, workshifts: GeneralData | null) =>
      dispatch(updateWorkshiftFilter(workshift, workshifts)),
    workshiftFetchData: (dateStart: moment.Moment, dateEnd: moment.Moment, withGateway: boolean) =>
      dispatch(workshiftFetchData(dateStart, dateEnd, withGateway)),
    workshiftValidityFetchData: (withGateway, dateFilter, fetchDayAfter, cutoff) =>
      dispatch(workshiftValidityFetchData(withGateway, dateFilter, fetchDayAfter, cutoff)),
  }
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

class WorkshiftSelector extends React.PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      notifications: [],
      workshifts: [],
    }

    moment.locale('it')

    this.handleWorkshiftChange = this.handleWorkshiftChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
    /****/
    const url = new URL(window.location.href)
    const manualWorkshift = url.searchParams.get('manualWorkshift')
    /****/

    if (
      nextProps.dateFilterStart !== this.props.dateFilterStart ||
      nextProps.dateFilterEnd !== this.props.dateFilterEnd ||
      nextProps.hideWorkshiftSelector !== this.props.hideWorkshiftSelector ||
      (!this.props.plant && nextProps.plant) ||
      this.props.plant.plant !== nextProps.plant.plant
    ) {
      this.props
        .workshiftFetchData(
          getMoment(nextProps.dateFilterStart),
          getMoment(nextProps.dateFilterEnd),
          this.props.isWithGateway
        )
        .then(() => {
          this.props
            .workshiftValidityFetchData(
              this.props.isWithGateway,
              nextProps.dateFilterStart,
              undefined,
              nextProps.model && nextProps.model.data && nextProps.model.data.model.content.data.cutOffHour
            )
            .then(data => {
              this.props.updateWorkshiftFilter((manualWorkshift && parseInt(manualWorkshift, 10)) || 1, data)
            })
        })
        .catch(e => {
          console.log(e) // tslint:disable-line
        })
    } else {
      if (!nextProps.hideWorkshiftSelector) {
        const workshiftData =
          (nextProps.workshifts &&
            nextProps.workshifts.data &&
            nextProps.workshifts.data.values &&
            nextProps.workshifts.data.values.map((a: any) => a[1])) ||
          []

        const workshifts = workshiftData.filter((v: any, i: number, a: any[]) => a.indexOf(v) === i && v !== 0).sort()

        if (!nextProps.workshift) {
          this.props.updateWorkshiftFilter(
            (manualWorkshift && parseInt(manualWorkshift, 10)) || 1,
            nextProps.workshifts
          )
        }

        this.setState({ workshifts })
      }
    }
  }

  public componentDidMount(): void {
    const url = new URL(window.location.href)
    const manualDate = url.searchParams.get('manualDate')

    if (!manualDate || !moment(manualDate).isValid()) {
      const date = isMoment(this.props.dateFilterStart)
        ? this.props.dateFilterStart
        : moment(this.props.dateFilterStart)
      // check if panoramic view, if true max date is yesterday
      if (date.isSame(moment(), 'day') && this.props.hideWorkshiftSelector) {
        const newDate = moment().subtract(1, 'day')
        this.props.updateDateFilter(moment().subtract(1, 'day'), moment().subtract(1, 'day'))
        alert(
          this.props.t('plantAnalysis.dateChangedDayBefore', {
            fromDate: moment().format('DD/MM/YYYY'),
            toDate: newDate.format('DD/MM/YYYY'),
          })
        )
      } else {
        if (this.props.dateFilterStart && this.props.dateFilterEnd) {
          this.props
            .workshiftFetchData(
              getMoment(this.props.dateFilterStart),
              getMoment(this.props.dateFilterEnd),
              this.props.isWithGateway
            )
            .then(data => {
              this.props.updateWorkshiftFilter(1, data)
            })
            .catch(e => {
              console.log(e) // tslint:disable-line
            })
        }
      }
    }
  }

  public render() {
    const dateStart = isMoment(this.props.dateFilterStart)
      ? this.props.dateFilterStart
      : moment(this.props.dateFilterStart)
    const dateEnd = isMoment(this.props.dateFilterEnd) ? this.props.dateFilterEnd : moment(this.props.dateFilterEnd)
    const { t, isRangeDatePicker } = this.props

    const yesterday = moment().subtract(1, 'day')
    const presets = [
      {
        text: t('dateRangePicker.labels.yesterday'),
        start: yesterday,
        end: yesterday,
      },
      {
        text: t('dateRangePicker.labels.week'),
        start: yesterday.clone().subtract(1, 'week'),
        end: yesterday,
      },
      {
        text: t('dateRangePicker.labels.month'),
        start: moment()
          .clone()
          .subtract(1, 'month'),
        end: yesterday,
      },
    ]

    if (!this.props.isRenderVisible) {
      return null
    }

    return (
      <>
        <DateRangePickerComponent
          onApply={this.handleDateChange}
          singleDatePicker={!isRangeDatePicker}
          showDropdowns={true}
          startDate={dateStart}
          endDate={dateEnd}
          maxDate={this.props.hideWorkshiftSelector ? moment().subtract(1, 'day') : moment()}
          presets={presets}
        />

        {!this.props.hideWorkshiftSelector && this.state.workshifts && (
          <select
            onChange={this.handleWorkshiftChange}
            className="form-control mt-2"
            value={(this.props.workshift && this.props.workshift.value) || ''}
          >
            <option value={undefined}>{t('plantAnalysis.actions.selectWorkshiftText')}</option>
            {this.state.workshifts &&
              this.state.workshifts.map((e: number) => (
                <option key={e} value={e}>
                  {t('plantAnalysis.options.workshift.' + e)}
                </option>
              ))}

            <option key={99} value={99}>
              {t('plantAnalysis.options.workshift.99')}
            </option>
          </select>
        )}
      </>
    )
  }

  private handleWorkshiftChange(workshift: ChangeEvent<HTMLSelectElement>) {
    this.props.updateWorkshiftFilter(parseInt(workshift.currentTarget.value, 10), this.props.workshifts)
  }

  private handleDateChange(startDate: moment.Moment, endDate: moment.Moment) {
    this.props.updateDateFilter(startDate, endDate)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WorkshiftSelector))
